<template>
  <div class="myFamilyList">
    <div class="wrapper">
      <template v-if="familyList.length > 0">
        <div class="member" v-for="(item, index) in familyList" :key="index">
          <div class="avatar">
            <img
              :src="
                item.memberAvatar
                  ? item.memberAvatar
                  : require('@/assets/img/default_avatar.png')
              "
              alt=""
            />
          </div>
          <div class="name">
            <span>{{ item.memberName }}</span>
          </div>
          <div class="icon" @click.stop="deleteRelation(item)">
            <img src="./img/delete_trash.png" alt="" />
          </div>
        </div>
      </template>

      <div class="empty" v-else>
        <div class="text">暂无成员～</div>
      </div>
      <div class="operaBtn">
        <div class="btn" @click="addFamilyMember">添加成员</div>
      </div>
    </div>

    <v-dialog
      v-model="isDialog"
      title="提示"
      :confirmButtonColor="'#347EFFFF'"
      @confirm="confirm"
    >
      <div class="tips">
        <div class="tips-t">确定要删除这个家人?</div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { getMyFamilyMemberListUrl, deleteFamilyMemberUrl } from "./api";

export default {
  name: "myFamily",
  data() {
    return {
      isDialog: false,
      id: "", // 家人id
      familyList: [],
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  created() {
    this.getMyFamilyMemberList();
  },
  methods: {
    // 删除确认弹框
    deleteRelation(item) {
      console.log(0);
      this.isDialog = true;
      this.id = item.id;
    },
    // 确认删除接口
    confirm() {
      this.addSelf = "";
      let params = {
        userId: this.userId,
        id: this.id,
      };
      this.$axios
        .post(`${deleteFamilyMemberUrl}`, null, { params })
        .then((res) => {
          this.isDialog = false;
          if (res.code === 200) {
            this.getMyFamilyMemberList();
            this.$toast({ message: "操作成功", duration: 300 });
          } else {
            if (res.msg) {
              this.$toast({ message: res.msg, duration: 300 });
            }
          }
        });
    },
    // editFamilyMember(item) {
    //   this.$router.push({
    //     name: "addFamily",
    //     query: {
    //       id: item.id,
    //     },
    //   });
    // },
    addFamilyMember() {
      this.$router.push({ name: "addFamilyMember" });
    },
    // 获取我的家人列表
    getMyFamilyMemberList() {
      let params = {
        userId: this.userId,
      };
      this.$axios.get(getMyFamilyMemberListUrl, { params }).then((res) => {
        if (res.code === 200 && res.success) {
          this.familyList = res.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myFamilyList {
  box-sizing: border-box;
  min-height: 100%;
  background: #fafafaff;
  margin-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  margin-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .wrapper {
    padding: 88px 38px 132px;
    .member {
      display: flex;
      justify-content: center;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 44px;
      }
      .avatar {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          vertical-align: middle;
        }
      }
      .name {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 32px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.72);
        padding-left: 32px;
      }
      .icon {
        width: 60px;
        text-align: center;
        img {
          width: 40px;
          height: 48px;
          vertical-align: center;
        }
      }
    }
    .operaBtn {
      width: 100%;
      position: fixed;
      z-index: 1;
      bottom: 40px;
      left: 0;
      display: flex;
      justify-content: center;
      padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
      padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
      .btn {
        width: 550px;
        height: 66px;
        font-size: 28px;
        line-height: 66px;
        text-align: center;
        font-weight: bold;
        color: #ffffff;
        background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
        border-radius: 10px;
      }
    }
  }
  .empty {
    padding: 196px 0 0;
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 36px;
  }
  .tips {
    .tips-t {
      font-size: 34px;
      text-align: center;
      padding: 0 20px;
      margin: 40px 0;
    }
  }
}
</style>
